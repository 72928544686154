.Dropzone {
  height: 200px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed #fdb61f;
  border-radius: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
  color: #fdb61f;
}

.FileInput {
  display: none;
  justify-content: center;
}

.labelDropzone {
  font-size: 0.7rem;
  color: #013f8a;
}

.labelDropzoneOp {
  font-size: 0.5rem;
  color: #013f8a;
}
