@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url('../../assets/fonts/Inter/Inter-Regular.ttf') format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url('../../assets/fonts/Inter/Inter-Light.ttf') format("truetype");
    font-weight: lighter;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url('../../assets/fonts/Inter/Inter-SemiBold.ttf') format("truetype");
    font-weight: bold;
}

.ReportsApp, .ReportsApp * {
    font-family: 'Inter';
    background-color: #F6F7FF;
}



.col-principal {
    width: 75vw;
    margin-left: auto;
    margin-right: auto;
}

.col-titulo {
    margin-top: 10%;
}

.col-titulo p {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #4a4563;
}

.col-filtros {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    align-items: center;
}

.col-filtros p {
    
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #4a4563;
   
}

.col-filtros p.filter-title {
    margin-right: 8%;
}

.col-filtros .filtros {
    
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-radius: 10px;
    border: 2px solid #B5B5B5;

    margin-left: 15px;

    max-height: 40px;
    align-items: center;

    min-width: 185px;

}

.col-filtros .filtros button {
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;
    
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.112019px;
    color: #b5b5b5;

    border: 1px #F6F7FF;
    background-color: #F6F7FF;
    border-radius: 45px;
}

.col-filtros .filtros p {
    height: 35px;
    padding-left: 30px;
    padding-right: 30px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.112019px;
    color: #b5b5b5;

    margin-top: 0;
    margin-bottom: 0;
}

.col-filtros button.campania {   

    
    font-weight: 600;
    font-size: 15px;
    color: #fff;
    background: #9d6efb;
    border: 1px solid #9d6efb;
    border-radius: 12px;
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.col-tabla {
    margin-top: 5vh;
    box-shadow: 0 3px 10px #000;
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 60px;

    background-color: #fff;
}

.col-tabla table {
    width: 100%;
    border-collapse: collapse;
}

.col-tabla table th {
    
    font-weight: 600;
    font-size: 20px;
    color: #9d6efb;
    border-bottom: 3px solid #9d6efb;
    padding-bottom: 10px;

    width: 16%;
    background-color: #fff;
}

.col-tabla table th.first {
    text-align: left;
    padding-left: 25px;

    width: 20%;
}

.col-tabla table th.last {
    text-align: right;
    padding-right: 25px;
}

.col-tabla table th.pagination {
    text-align: right;
    border-bottom: 3px solid #fff;
    padding-right: 25px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.col-tabla table th.pagination button {
    
    font-weight: 600;
    font-size: 24px;
    color: #9d6efb;
    background-color: #fff;
    border: 1px solid #fff;
    padding-left: 60px;
}

.col-tabla table th.pagination button.text {
    
    font-weight: 500;
}

.col-tabla table td {
    padding-top: 30px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;

    width: 16%;
    color: #4A4563;
    background-color: #fff;

    /* padding-bottom: 15px;
    border-bottom: 3px solid #C3C2CD; */
}

.col-tabla table tr.campania {
    border-top: 2px solid #C3C2CD; 
}

.col-tabla table tr.campania td {
    padding-bottom: 20px;
}

.col-tabla table td.first {
    width: 20%;
}

.col-tabla table td:last-child {
    /* display: inline-block; */
}

.col-tabla table td .total {
    display: inline-block;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 15px;
    border: 2px solid #8d3bf5;
    border-radius: 10px;
    background-color: #fff;
}

.col-tabla table td button {
    
    font-style: normal;
    font-size: 17px;
    line-height: 29px;
    background-color: #fff;
    border: solid 2px #fff;

    width: 95%;
    margin-right: 5%;
    color: #4A4563;
}

.col-tabla table tr.innactive td button, .col-tabla table tr.innactive td {
    color: #C3C2CD;
}

.col-tabla table td button .icon {
    padding-left: 4px;
    color: #9d6efb;
    background-color: #fff;
}

.col-tabla table td:first-child {
    text-align: left;
}

.col-tabla table.sub-table {
    border-collapse: separate;
    border-spacing: 0;
}

.col-tabla table.sub-table tr.headers {
    background-color: #9d6efb !important;
}

.col-tabla table.sub-table tr.headers th {
    background-color: #9d6efb !important;
    font-weight: 550;
    font-size: 18px;
    color: #fff;    
    max-width: 20%;
    width: 20%;
    min-width: 18%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.col-tabla table.sub-table tr.headers th.first {
    text-align: left;
    padding-left: 25px;
    width: 12%; 
}

.col-tabla table.sub-table tr.headers th.last {
    text-align: center;
}

.col-tabla table.sub-table tr.headers th,
.col-tabla table.sub-table tr.headers td {
    border: solid 1px #9d6efb;
    border-style: none solid solid none;
}

.col-tabla table.sub-table td:first-child {
    width: 12%; 
}
.col-tabla table.sub-table td:first-child  input.table-input {
    width: 100px;    
}

.col-tabla table.sub-table td.input-cell {
    padding-top: 10px;
}

.col-tabla table.sub-table tr:first-child th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.col-tabla table.sub-table tr:first-child th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.col-tabla table.sub-table input.table-input {
    
    font-style: normal;
    font-size: 16px;
    line-height: 29px;
    border: solid 2px #9d6efb;
    background-color: #fff;
    border-radius: 8px;
    padding-left: 15px;

    max-width: 180px;
    min-width: 100px;
}

.col-tabla table .extra-menu {
    padding-bottom: 30px;
}

.col-botones {
    margin-top: 5vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.col-botones button {
    
    font-weight: 400;
    font-size: 14px;
    color: #6212f8;
    background: #fff;
    border: 1px solid #6212f8;
    border-radius: 8px;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.col-botones button:hover {
    border-radius: 12px;
}

.col-botones button:active {
    background-color: #6212f8;
    color: #fff;
  }