@keyframes ldio-opn7nv6o23j-1 {
  0% {
    top: 21.5px;
    height: 57px;
  }
  50% {
    top: 31px;
    height: 38px;
  }
  100% {
    top: 31px;
    height: 38px;
  }
}
@keyframes ldio-opn7nv6o23j-2 {
  0% {
    top: 23.875px;
    height: 52.25px;
  }
  50% {
    top: 31px;
    height: 38px;
  }
  100% {
    top: 31px;
    height: 38px;
  }
}
@keyframes ldio-opn7nv6o23j-3 {
  0% {
    top: 26.25px;
    height: 47.5px;
  }
  50% {
    top: 31px;
    height: 38px;
  }
  100% {
    top: 31px;
    height: 38px;
  }
}
.ldio-opn7nv6o23j div {
  position: absolute;
  width: 18px;
}
.ldio-opn7nv6o23j div:nth-child(1) {
  left: 16px;
  background: #100047;
  animation: ldio-opn7nv6o23j-1 0.5681818181818182s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: -0.11363636363636365s;
}
.ldio-opn7nv6o23j div:nth-child(2) {
  left: 41px;
  background: #871bef;
  animation: ldio-opn7nv6o23j-2 0.5681818181818182s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: -0.05681818181818182s;
}
.ldio-opn7nv6o23j div:nth-child(3) {
  left: 66px;
  background: #88d1d4;
  animation: ldio-opn7nv6o23j-3 0.5681818181818182s cubic-bezier(0, 0.5, 0.5, 1)
    infinite;
  animation-delay: undefineds;
}

.loadingio-spinner-pulse-oga6rtpgi8 {
  width: 88px;
  height: 88px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.ldio-opn7nv6o23j {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.88);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-opn7nv6o23j div {
  box-sizing: content-box;
}
