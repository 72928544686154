@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url('../../assets/fonts/Inter/Inter-Regular.ttf') format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url('../../assets/fonts/Inter/Inter-Light.ttf') format("truetype");
    font-weight: lighter;
}

@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url('../../assets/fonts/Inter/Inter-SemiBold.ttf') format("truetype");
    font-weight: bold;
}

.ReportsAuto-App * {
    font-family: 'Inter';
    /* background-color: #fff; */
}

.col-principal {
    width: 85vw;
    margin-left: auto;
    margin-right: auto;
}

.col-titulo {
    margin-top: 10%;
}

.col-titulo p {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #4a4563;
}

.col-filtros {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    align-items: center;
}

.col-opciones {
    margin-top: 5vh;
    box-shadow: 0 3px 10px #000;
    border-radius: 10px;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    background-color: #fff;
}


.col-opciones p {
    font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #9D6EFB;

}

.col-filtros select {

    border: 1px solid #B5B5B5;
    background-color: #fff;
    border-radius: 10px;

    padding-left: 20px;
    padding-right: 20px;

    height: 35px;

    color: #B5B5B5;

    font-weight: 500;
font-size: 16px;
line-height: 20px;

margin-left: 20px;
}

.col-opciones select {

    /* -webkit-appearance: none;
  appearance: none; */

    border: 1px solid #B5B5B5;
    /* border-bottom: 1px solid #B5B5B5;
    border-left: 1px solid #B5B5B5;
    border-right: 16px solid #B5B5B5; */

    background-color: #fff;

    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    height: 35px;

    padding-left: 10px;
    padding-right: 10px;
}




.col-botones {
    margin-top: 5vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.col-botones button {

    width: 150px;
    
    font-weight: 400;
    font-size: 14px;
    color: #6212f8;
    background: #fff;
    border: 1px solid #6212f8;
    border-radius: 10px;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
}

.col-botones button:hover {
    border-radius: 12px;
}

.col-botones button:active {
    background-color: #6212f8;
    color: #fff;
  }


.MuiFormControl-root.MuiTextField-root {
    max-width: 15%;
    padding-top: 15px;
}

.col-tabla-automatica table td:first-child {
    text-align: center !important;
}

.button-row {
    display: flex;
    flex-direction: row;
}

.miniReport {
    width: 30%;
    color: #6212f8 !important;
    background: #fff !important;
    border-radius: 10px;
    padding-top: 10px;
}

.col-error {
    background-color: #ee5e5e;
    color: #000000 ;
    padding-top: 10px;
    padding-bottom: 10px;

}

.col-error p {
    text-align: center;
    font-size: 16px;
}


.col-final {
    background-color: #b790ff;
    color: #000000 ;
    padding-top: 10px;
    padding-bottom: 10px;

}

.col-final p {
    text-align: center;
    font-size: 16px;
}